.rc-slider {
    margin-top: 15px;

    .rc-slider-handle {
        background-color: white;
        border-color: white;
        box-shadow: 0 0 5px $primary-01;

        &:hover {
            border-color: $primary-01;
        }
    }

    .slider-footer {
        justify-content: space-between;
        align-items: center;
        display: flex;
    }

    .rc-slider-track {
        background-color: $primary-01;
    }
}
