.atlas-modal {
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
        background-color: $app-bg-color-light;
        min-width: 300px;

        &:focus {
            outline: none;
        }

        .header {
            padding: 16px;
            background-color: $primary-01;
            text-align: center;
            color: white;
        }

        .page {
            padding: 16px;
            background-color: $app-bg-color;

            &.no-padding {
                padding: 0;
            }

            .section {
                margin-bottom: 16px;
            }

            .button-container {
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}

.atlas-dialog {
    .dialog-header {
        display: flex;
        align-items: center;
        padding: 16px;
        background-color: $app-bg-color-light;
        border-bottom: 1px solid $border-color;
        .dialog-title {
            background-color: $app-bg-color-light;
            flex-grow: 1;
            margin-right: 16px;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 30px;
        }
    }
    .container {
        background-color: $app-bg-color-light;
        padding: 16px;
        .field {
            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }
    }
}
.MuiPaper-root {
    .MuiTextField-root {
        margin-bottom: 10px;
    }
}

.MuiDialog-container {
    .MuiTextField-root {
        margin-bottom: 10px;
    }

    ::-webkit-scrollbar {
        width: 20px;
        height: 33px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: $neutral-04;
    }

    ::-webkit-scrollbar-track {
        border: $app-bg-color-light;
    }
}
