#layer-menu {
    background-color: white;
    display: block;
    .group-root {
        box-shadow: 0 2px 12px 1px rgba(60, 62, 66, 0.1);
        margin: 0px 16px;
        border-radius: 3px;
        background-color: white;
        &:not(:last-child) {
            margin-bottom: 8px;
        }

        .group {
            display: flex;
            align-items: center;
            padding-left: 16px;
            height: 48px;
            cursor: pointer;

            &:hover {
                color: $primary-01;
            }

            &.selected {
                background-color: $primary-01;
                color: white;
            }
        }
    }

    .virtual-item > .layer {
        margin-right: 32px;
        margin-left: 32px;
    }

    .layer {
        display: flex;
        align-items: center;

        height: 24px;

        &:not(:last-child) {
            margin-bottom: 16px;
        }

        &:hover {
            color: $primary-01;
        }

        &.selected {
            background-color: $primary-01;
        }
    }

    .sub-group-root {
        &:not(:last-child) {
            margin-bottom: 16px;
        }

        .sub-group {
            display: flex;
            align-items: center;

            height: 24;
            cursor: pointer;

            &:hover {
                color: $primary-color;
            }
        }
        .layer {
            padding-left: 16px;
            margin-right: 0;
            margin-left: 0;
        }
    }

    .ReactVirtualized__Grid__innerScrollContainer {
        & > .layer {
            padding: 0px 32px;
        }
    }

    .virtual-item {
        transition: 200ms all ease-out;
    }
}
