body {
    margin: 0;
    padding: 0;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
}

.mapboxgl-ctrl-group button.-active {
    background-color: lightgray;
}

.mapboxgl-ctrl-group button.active {
    background-color: lightgray;
}

.App,
#map,
#root,
body,
html,
.main-view {
    height: 100%;
    width: 100%;
}

.noselect {
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    /*for different browsers*/
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.menu {
    transition: all 0.4s;
}

.feature-info-table {
    table-layout: fixed;
    width: 100%;
    border-spacing: 0px;
    table-layout: auto !important;
}

.feature-info-table tr {
    font-size: 12px;
}

.feature-info-table th {
    padding: 16px;
    border-bottom: whitesmoke 1px solid;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 5px;
}

.feature-info-table th div {
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
}

.autocomplete-icon {
    font-size: 14px !important;
    margin-right: 8px;
}

.main-grid {
    display: grid;
    position: relative;
    overflow: hidden;
    grid-template-columns: min-content min-content minmax(20%, 100%);
    grid-template-rows: 100% 100% auto;
    grid-template-areas:
        "toolbar wrapper map"
        "toolbar wrapper map-table";
}

.flex-container {
    grid-area: map;
    display: flex;
    flex-direction: column;
}

.topbar {
    grid-area: topbar;
    height: 50px;
    width: 100%;
}

.sidebar-wrapper {
    grid-area: wrapper;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    position: relative;
}

.sidebar {
    background-color: #f5f7f8;
    width: 500px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: 100vh;
}

.sidebar.hide {
    width: 0;
    margin-left: 1rem;
}

.collapse-button {
    position: absolute;
    right: -14px;
    top: 2rem;
    z-index: 1101;
}

.collapse-button:hover {
    cursor: pointer;
}

.collapse-button__icon {
    color: rgba(0, 0, 0, 0.8);
    background-color: #ffffff;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.15), 0px 0px 0px 0px rgba(0, 0, 0, 0.15),
        0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
}

.title {
    display: flex;
    height: 30px;
    align-items: center;
    padding: 8px 20px;
}

.topbar.hide {
    display: none;
}

.toolbar {
    grid-area: toolbar;
    width: 60px;
    display: flex;
    flex-flow: column;
    max-height: 100vh;
}

.toolbar.open {
    width: 180px;
}

#map {
    height: 100%;
}

#map-table {
    grid-area: map-table;
}

#map-table-dragbar {
    grid-area: map-table-dragbar;
    background-color: black;
    width: 100%;
    height: 5px;
    cursor: ns-resize;
}

.mapboxgl-popup-close-button {
    width: 40px;
    height: 28px;
    font-size: 24px;
    color: white;
    z-index: 10;
}
.mapboxgl-popup-close-button:hover {
    background-color: #024264;
}

.mapboxgl-popup-content {
    padding: 0px;
    border-radius: 10px;
    overflow: auto;
    display: flex;
    max-height: 500px;
}

.mapboxgl-popup-content > div {
    overflow: auto;
    display: flex;
}

.mapboxgl-ctrl-icon {
    padding: 0px;
}

.mapboxgl-ctrl-logo {
    display: none;
}

@media only screen and (max-width: 600px) {
    .container {
        display: grid;
        grid-template-columns: min-content minmax(20%, 100%);
        grid-template-areas: "toolbar map";
    }

    .sidebar {
        grid-area: map;
        z-index: 5;
        min-width: 80vw;
    }
}

.mapboxgl-ctrl-attrib .mapbox-improve-map {
    display: none;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
    margin-right: 70px;
}

.mapboxgl-ctrl-attrib-inner {
    font-size: 10px;
}

.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-scale {
    margin: 0px 5px 5px 0px;
}

.mapboxgl-ctrl-pitchtoggle-3d {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+ICAgIDx0ZXh0IHg9IjUwJSIgeT0iNTAlIiBkeT0iLjM1ZW0iIHN0eWxlPSJmb250LXNpemU6IDE0cHg7IGZvbnQtZmFtaWx5OiAnSGVsdmV0aWNhIE5ldWUnLEFyaWFsLEhlbHZldGljYSxzYW5zLXNlcmlmOyBmb250LXdlaWdodDogYm9sZDsgdGV4dC1hbmNob3I6IG1pZGRsZTsiPjNEPC90ZXh0Pjwvc3ZnPg==);
}

.mapboxgl-ctrl-pitchtoggle-2d {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+ICAgIDx0ZXh0IHg9IjUwJSIgeT0iNTAlIiBkeT0iLjM1ZW0iIHN0eWxlPSJmb250LXNpemU6IDE0cHg7IGZvbnQtZmFtaWx5OiAnSGVsdmV0aWNhIE5ldWUnLEFyaWFsLEhlbHZldGljYSxzYW5zLXNlcmlmOyBmb250LXdlaWdodDogYm9sZDsgdGV4dC1hbmNob3I6IG1pZGRsZTsiPjJEPC90ZXh0Pjwvc3ZnPg==);
}

/* LOADER STYLE AND ANIMATION */

.loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
}
.progress-spinner {
    width: 48px;
    height: 48px;
    animation: progress-spinner-linear-rotate 2s linear infinite;
    display: block;
    position: relative;
}
.progress-spinner svg {
    width: 48px;
    height: 48px;
    position: absolute;
    transform: rotate(-90deg);
    top: 0;
    left: 0;
    transform-origin: center;
    overflow: visible;
    vertical-align: middle;
}
.progress-spinner circle {
    animation-name: progress-spinner-stroke-rotate-48;
    stroke-dasharray: 119.381px;
    stroke-width: 10%;
    stroke: #024f79;
    fill: transparent;
    transform-origin: center;
    transition: stroke-dashoffset 225ms linear;
    transition-property: stroke;
    animation-duration: 4s;
    animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
    animation-iteration-count: infinite;
}
@keyframes progress-spinner-linear-rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes progress-spinner-stroke-rotate-48 {
    0% {
        stroke-dashoffset: 113.41149479459152;
        transform: rotate(0);
    }
    12.5% {
        stroke-dashoffset: 23.876104167282428;
        transform: rotate(0);
    }
    12.5001% {
        stroke-dashoffset: 23.876104167282428;
        transform: rotateX(180deg) rotate(72.5deg);
    }
    25% {
        stroke-dashoffset: 113.41149479459152;
        transform: rotateX(180deg) rotate(72.5deg);
    }
    25.0001% {
        stroke-dashoffset: 113.41149479459152;
        transform: rotate(270deg);
    }
    37.5% {
        stroke-dashoffset: 23.876104167282428;
        transform: rotate(270deg);
    }
    37.5001% {
        stroke-dashoffset: 23.876104167282428;
        transform: rotateX(180deg) rotate(161.5deg);
    }
    50% {
        stroke-dashoffset: 113.41149479459152;
        transform: rotateX(180deg) rotate(161.5deg);
    }
    50.0001% {
        stroke-dashoffset: 113.41149479459152;
        transform: rotate(180deg);
    }
    62.5% {
        stroke-dashoffset: 23.876104167282428;
        transform: rotate(180deg);
    }
    62.5001% {
        stroke-dashoffset: 23.876104167282428;
        transform: rotateX(180deg) rotate(251.5deg);
    }
    75% {
        stroke-dashoffset: 113.41149479459152;
        transform: rotateX(180deg) rotate(251.5deg);
    }
    75.0001% {
        stroke-dashoffset: 113.41149479459152;
        transform: rotate(90deg);
    }
    87.5% {
        stroke-dashoffset: 23.876104167282428;
        transform: rotate(90deg);
    }
    87.5001% {
        stroke-dashoffset: 23.876104167282428;
        transform: rotateX(180deg) rotate(341.5deg);
    }
    100% {
        stroke-dashoffset: 113.41149479459152;
        transform: rotateX(180deg) rotate(341.5deg);
    }
}
.grecaptcha-badge {
    visibility: hidden;
}
