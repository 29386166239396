.rotate180 {
    transform: rotate(180deg);
}

.grow {
    flex-grow: 1;
}

.button-icon {
    margin-right: 8px;
}