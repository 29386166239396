// BRAND COLORS

$primary-01: #024f79;
$primary-02: #67a9c1;
$primary-03: #00749a;
$primary-04: #004166;

$secondary-01: #d3e2ea;
$accent-01: #0393e2;

// NEUTRAL COLORS

$black: #000;
$neutral-01: #222222;
$neutral-02: #616c6f;
$neutral-03: #848f94;
$neutral-04: #e3e4e5;
$neutral-05: #f0f0f0;
$neutral-06: #f5f7f8;
$white: #fff;

// SEMANTIC COLORS

$semantic-01: #149b62;
$semantic-02: #ec3c3e;
$semantic-03: #f67500;
$semantic-04: #f5ad00;

// COMPONENTS COLORS

$app-bg-color: $neutral-06;
$app-bg-color-light: $white;
$app-bg-color-dark: $neutral-03;

$success-bg: $semantic-01;
$error-bg: $semantic-02;
$warning-bg: $semantic-04;
$info-bg: $secondary-01;

$success-text-color: $white;
$error-text-color: $white;
$warning-text-color: $neutral-01;
$info-text-color: $primary-01;

$app-text-color: $neutral-01;
$app-text-color-light: $neutral-02;
$muted-color: $neutral-03;
$icon-color: $app-text-color;
$icon-color-light: $app-text-color-light;
$border-color: $neutral-04;

// Used on styler module
// TODO: these variables should have default values defined on Styler module
// in order to avoid compiling errors where module is imported
$primary-color: $primary-01;
$primary-text-color: $app-text-color;
$primary-icon-color: $icon-color;
$secondary-text-color: $app-text-color-light;
$primary-background-color: $app-bg-color;
$scondary-background-color: $app-bg-color-light;
$tertiary-background-color: $app-bg-color-dark;

// Export colors to MuiTheme

:export {
    primaryColor: $primary-01;
    secondaryColor: $secondary-01;
    primaryBackgroundColor: $app-bg-color;
    successColor: $semantic-01;
    secondaryBackgroundColor: $app-bg-color-light;
    primaryTextColor: $app-text-color;
    secondaryTextColor: $app-text-color-light;
    primaryWarningColor: $semantic-03;
    primaryErrorColor: $semantic-02;
    lightGray: $neutral-04;
    borderColor: $neutral-04;
    mutedColor: $muted-color;
}
