@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@import "react-redux-toastr/src/styles/index";

@import "./variables.module";

@import "./modal.scss";

@import "./styler.scss";
@import "./layerGroup.scss";
@import "./utilities.scss";
@import "./toastr-overrides.scss";
@import "./scroll-bar-overrides";
@import "./vendors-overrides.scss";

@import "@emblautec/layer-styler-component/src/_editStyleView.scss";
@import "@emblautec/layer-styler-component/src/components/styleProperties/_styleProperties.scss";

.map-attribution{
    cursor: pointer;
}