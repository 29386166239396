::-webkit-scrollbar-track {
    background-color: white;
    border-top: 1px solid $border-color;
    border-left: 1px solid $border-color;
}

::-webkit-scrollbar-track:vertical {
    border-top: white;
}

::-webkit-scrollbar-corner {
    border: none;
    background-color: white;
}

::-webkit-scrollbar {
    width: 25px;
    height: 36px;
}

::-webkit-scrollbar-thumb {
    background-color: $neutral-02;
    border-radius: 100px;
    border: 13px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
}

::-webkit-scrollbar-thumb:vertical {
    border: 8px solid rgba(0, 0, 0, 0);
}

.mapboxgl-popup-content {
    ::-webkit-scrollbar {
        width: 20px;
        height: 33px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: $neutral-04;
    }

    ::-webkit-scrollbar-track {
        border: white;
    }
}
