.edit-view-container {
    position: absolute;
    min-width: 300px;
    height: 100%;
    width: 100%;
    top: 0;
    overflow: auto;
    display: flex;
    flex-direction: column;
    background-color: $app-bg-color;

    .close-button {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        padding-right: 16px;
        padding-left: 16px;
    }

    .edit-page {
        padding-top: 16px;
        .dataset-info {
            padding: 16px;
            background-color: $app-bg-color-light;

            .grow {
                flex-grow: 1;
            }
        }

        .checkbox {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .container {
            padding: 0px 16px;
        }

        .container:not(:first-child) {
            margin-top: 8px;
        }
    }
}
